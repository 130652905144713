import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Hot reload on a public domain`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "path=webpack.mix.js",
        "path": "webpack.mix.js"
      }}>{`mix.options({
    hmr: false,
    hmrOptions: {
        host: String(process.env.APP_URL).split('://').pop(),
        port: '443',
    },
});
mix.browserSync({
    files: [
        'app/**',
        'resources/**',
    ],
    watchOptions: {
        awaitWriteFinish: true,
    },
    proxy: {
        target: 'http://127.0.0.1:8000',
        proxyReq: [
            function(proxyReq) {
                proxyReq.setHeader('X-Forwarded-Host', String(process.env.APP_URL).split('://').pop());
            },
        ],
    },
    socket: {
        domain: String(process.env.APP_URL).split('://').pop(),
    },
    https: true,
    reloadOnRestart: true,
    open: false,
    notify: false,
});
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-php",
        "metastring": "path=app/Http/Middleware/TrustProxies.php",
        "path": "app/Http/Middleware/TrustProxies.php"
      }}>{`protected $proxies = [
    '127.0.0.1',
];
`}</code></pre>
    <p><strong parentName="p">{`Start a PHP development server on http://localhost:8000`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`php artisan serve
`}</code></pre>
    <p><strong parentName="p">{`Start a NPM development server on https://localhost:3000`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npx mix watch --hot --https
`}</code></pre>
    <p><strong parentName="p">{`Proxy the mix server on a custom domain using Valet`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`valet proxy <some site name> https://localhost:3000 --secure
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      